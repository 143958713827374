export const DATE_FORMAT: { [key: string]: string } = {
    'en-GB': 'MM/dd/yyyy',
    'en-US': 'dd/MM/yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'sv-SE': 'yyyy-MM-dd',
    'fi-FI': 'd.M.yyyy',
    'nl-NL': 'd-M-yy',
    'da-DK': 'dd/MM/yy'
}

export const DATE_FORMAT_CALENDAR: { [key: string]: string } = {
    'en-GB': 'mm/dd/yy',
    'en-US': 'dd/mm/yy',
    'nb-NO': 'dd.mm.yy',
    'sv-SE': 'yy-mm-dd',
    'fi-FI': 'd.m.yy',
    'nl-NL': 'd-m-y',
    'da-DK': 'dd/mm/y'
}