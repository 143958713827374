import { Distributor } from "../../entity-details/entity-details-tab/user-tabs/user-tab/distributor";

export const ENTITY_ITEM_ID_KEYS_MAP: { [key: string]: string } = {
    user: 'connectUserId',
    supportUser: 'connectUserId',
    userGroup: 'userGroupInvariantKey',
    company: 'tenantId',
    companyGroup: 'companyGroupInvariantKey',
    role: 'roleInvariantKey',
    roleGroup: 'roleInvariantKey',
    customerOfVisma: 'tenantId',
    clientCompany: 'tenantId',
    distributor: 'tenantId',
}

