import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UIParentItem } from '../entity-list/ui-parent-item';

@Injectable({
  providedIn: 'root'
})
export class EntityOptionsMenuDataService {

  uiParentItem: UIParentItem | null = null;

  private _uiParentItem = new Subject<UIParentItem | null>();
  public uiParentItem$: Observable<UIParentItem | null> = this._uiParentItem.asObservable();

  changeUIParentItem(uiParentItem: UIParentItem | null): void {
    this.uiParentItem = uiParentItem;
    this._uiParentItem.next(uiParentItem);
  }

  getUIParentItem(): UIParentItem | null {
    return this.uiParentItem;
  }

  resetUIParentItem(): void {
    this.uiParentItem = null;
    this._uiParentItem.next(null);
  }
}
