import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Application } from './application';
import { PROFILE_ROLE } from '../core/auth/profile-role';
import { PATH } from '../core/const/path';
import { Tenant } from '../core/auth/tenant';
import { ContextGroup } from './context-group';
import { ENTITY_TYPE } from '../core/const/entity-type';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor(private httpClient: HttpClient) { }

  getApplications(): Observable<Application[]> {
    return this.httpClient.get<Application[]>('/v1/me/applications');
  }

  getContextGroups(list: Tenant[], msg: { [key: string]: string }): ContextGroup[] {
    const contextGroups: ContextGroup[] = [];
    let items: Tenant[] = [];
    const roles = [PROFILE_ROLE.subscriberAdministrator, PROFILE_ROLE.companyAdministrator, PROFILE_ROLE.buAccessAdministrator];
    for (let i = 0, len = roles.length; i < len; i++) {
      if (roles[i] === PROFILE_ROLE.subscriberAdministrator) {
        items = list.filter(tenant => tenant.roles.includes(PROFILE_ROLE.subscriberAdministrator));
        if (items.length > 0) {
          contextGroups.push(new ContextGroup(msg['labelSubscriber'], ENTITY_TYPE.customer, items));
        }
      } else if (roles[i] === PROFILE_ROLE.companyAdministrator) {
        items = list.filter(tenant => tenant.roles.includes(PROFILE_ROLE.companyAdministrator));
        if (items.length > 0) {
          contextGroups.push(new ContextGroup(msg['labelCompany'], ENTITY_TYPE.company, items));
        }
      } else if (roles[i] === PROFILE_ROLE.buAccessAdministrator || roles[i] === PROFILE_ROLE.buSubscriberViewer) {
        items = list.filter(tenant => tenant.roles.includes(PROFILE_ROLE.buAccessAdministrator) ||
          tenant.roles.includes(PROFILE_ROLE.buSubscriberViewer));
        if (items.length > 0) {
          contextGroups.push(new ContextGroup(msg['labelBusinessUnit'], ENTITY_TYPE.distributor, items));
        }
      }
    }
    if (contextGroups.length === 1) {
      contextGroups[0].type = ENTITY_TYPE.none;
    }
    return contextGroups;
  }

  getRootContextPath(roles: string[]): string {
    if (roles.includes(PROFILE_ROLE.buAccessAdministrator) || roles.includes(PROFILE_ROLE.buSubscriberViewer)) {
      return PATH.context_distributor;
    } else if (roles.includes(PROFILE_ROLE.subscriberAdministrator)) {
      return PATH.context_customer;
    } else if (roles.includes(PROFILE_ROLE.companyAdministrator)) {
      return PATH.context_company;
    }
    return '/forbidden';
  }

  getAppName(roles: string[]): string {
    if (roles.includes(PROFILE_ROLE.buAccessAdministrator) || roles.includes(PROFILE_ROLE.buSubscriberViewer)) {
      return 'Admin - BU';
    } else if (roles.includes(PROFILE_ROLE.subscriberAdministrator)) {
      return 'Admin';
    } else if (roles.includes(PROFILE_ROLE.companyAdministrator)) {
      return 'Admin - Company';
    }
    return 'Admin';
  }

  isMobile(): boolean {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= 420) {
      return true;
    }
    return false;
  }

  hasAllElementsDisplayed(navbarBrandWidth: number, menuItemsWidth: number,
    userDropDownWidth: number) {
    const clientWidth = document.body.clientWidth;
    const aw = clientWidth - navbarBrandWidth - menuItemsWidth - userDropDownWidth - 56;
    if (aw > 0) {
      return true;
    }
    return false;
  }

  getActivelMenuItemIndex(arrMenuItems: ElementRef<HTMLLIElement>[]): number {
    let index = -1;
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      if (arrMenuItems[i].nativeElement.classList.contains('active')) {
        index = i;
        break;
      }
    }
    return index;
  }

  getMenuItemsTotalWidth(arrMenuItems: ElementRef<HTMLLIElement>[]): number {
    let width = 0;
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      width += arrMenuItems[i].nativeElement.offsetWidth;
    }
    return width;
  }

  hasDroppedMenuItems(arrMenuItems: ElementRef<HTMLLIElement>[]): boolean {
    let has = false;
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      if (arrMenuItems[i].nativeElement.classList.contains('hidden')) {
        has = true;
        break;
      }
    }
    return has;
  }

  hasDroppedAllMenuItems(arrMenuItems: ElementRef<HTMLLIElement>[]): boolean {
    let has = true;
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      if (!arrMenuItems[i].nativeElement.classList.contains('hidden')) {
        has = false;
        break;
      }
    }
    return has;
  }

}
