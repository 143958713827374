import { Injectable } from "@angular/core";
import { AdminWindow } from "../../common/core/domain/admin-window";
import { Profile } from "../../common/core/auth/profile";
import { WootricOptions } from "./wootric-options";
import { PROFILE_ROLE } from "../../common/core/auth/profile-role";

@Injectable({
  providedIn: 'root'
})
export class WootricService {

  constructor() { }

  init(options: WootricOptions) {
    const w = (window as AdminWindow & typeof globalThis);
    w.wootricSettings = options;
    w.wootric('run');
  }

  getOptions(profile: Profile, msgText: string, msgLink: string): WootricOptions {
    const context = profile.currentTenant;
    const language = profile.locale.split('-')[0];
    const roles = profile.roles;
    let options: WootricOptions = {} as WootricOptions;

    if (roles.includes(PROFILE_ROLE.subscriberAdministrator) || roles.includes(PROFILE_ROLE.companyAdministrator)) {
      options = {
        email: profile.email,
        created_at: profile.created_at,
        account_token: 'NPS-EU-7fc8dc2f',
        language: language,
        product_name: '',
        disclaimer: {
          text: msgText,
          link: 'https://www.visma.com/privacy/customer-feedback/',
          link_word: msgLink
        },
        properties: {
          distributor: context.business_unit_name,
          customerID: context.external_id,
          customerName: context.organization_name,
          feature: 'New Customer',
          role: roles.toString(),
        },
        paths: []
      };
    }
    return options;
  }

}
