export const ENTITY_TYPE  = {
    none: 'none',
    user: 'User',
    userGroup: 'UserGroup',
    supportUser: 'SupportUser',
    auditor: "Auditor",
    company: 'Company',
    companyGroup: 'CompanyGroup',
    clientCompany: 'ClientCompany',
    client: 'Client',
    companyGroupClient: 'CompanyGroupClient',
    role: 'Role',
    roleGroup: 'RoleGroup',
    customer: 'CustomerOfVisma',
    distributor: 'Distributor',
    partner: 'Partner'
}
