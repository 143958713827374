export const environment = {
  production: true,
  defaultLocale: 'en-GB',
  supportedLocale: ['da-DK', 'en-GB', 'en-US', 'fi-FI', 'nb-NO', 'nl-NL', 'sv-SE'],
  excludedUrlsFromRetryStrategy: ['/assets/i18n/', '/notifications-api/hashcode'],
  testUsers: ['odpuserta+userforinterview@gmail.com'],
  vismaHomeUri: {
    localhost: 'https://home.stag.visma.com/home',
    int: 'https://home.aws.int.test.visma.net/home',
    stag: 'https://home.stag.visma.com/home',
    prod: 'https://home.visma.com/home'
  },
  connectIdentity: {
    localhost: {
      wellknown_config: 'https://connect.identity.stagaws.visma.com/.well-known/openid-configuration',
      client_id: 'admin-ui',
      redirect_uri: 'http://localhost:4200/callback',
      post_logout_redirect_uri: 'http://localhost:4200',
      scope: 'openid+email+profile+custom+roles+offline_access+graphapi:read+authenticationsettingsapi:subscription:read+authenticationsettingsapi:role:read+authenticationsettingsapi:job:read+authenticationsettingsapi:job:create+access-management-api:notifications+access-management-api:customers:write+access-management-api:customers:read+access-management-api:companies:write+access-management-api:companies:read+access-management-api:users:write+access-management-api:users:read+access-management-api:customer-roles:write+access-management-api:company-roles:write+access-management-api:usergroups:write+access-management-api:usergroups:read+access-management-api:distributors:write+publicapi:user:update+access-management-api:users:custom-claims+access-management-api:companyGroups:read+access-management-api:companyGroups:write+access-management-api:rolegroups:read+access-management-api:rolegroups:write+access-management-api:defaultrolegroup:read+access-management-api:defaultrolegroup:write+access-management-api:roles:read+access-management-api:distributors:admin+access-management-api:distributors:viewer',
      account_settings: 'https://accountsettings.connect.identity.stagaws.visma.com',
      graph_api: "https://graph-api.connect.identity.stagaws.visma.com",
      user_details_uri: 'https://accountsettings.connect.identity.stagaws.visma.com/'
    },
    int: {
      wellknown_config: 'https://xjcyxjmpvh.execute-api.eu-west-1.amazonaws.com/int/.well-known/openid-configuration',
      client_id: '6pbpm58p097urg3ta1utiqkglv',
      redirect_uri: 'https://admin-user-access.aws.int.test.visma.net/callback',
      post_logout_redirect_uri: 'https://admin-user-access.aws.int.test.visma.net',
      scope: 'openid+email+profile+https://graph-api.connect.visma.com/graphapi:read+https://access-management.api.visma.net/access-management-api:notifications+https://access-management.api.visma.net/access-management-api:customers:write+https://access-management.api.visma.net/access-management-api:customers:read+https://access-management.api.visma.net/access-management-api:companies:write+https://access-management.api.visma.net/access-management-api:companies:read+https://access-management.api.visma.net/access-management-api:users:write+https://access-management.api.visma.net/access-management-api:users:read+https://access-management.api.visma.net/access-management-api:customer-roles:write+https://access-management.api.visma.net/access-management-api:company-roles:write+https://access-management.api.visma.net/access-management-api:usergroups:write+https://access-management.api.visma.net/access-management-api:usergroups:read+https://access-management.api.visma.net/access-management-api:distributors:write+https://public-api.connect.visma.com/publicapi:user:update+https://access-management.api.visma.net/access-management-api:users:custom-claims+https://access-management.api.visma.net/access-management-api:companyGroups:read+https://access-management.api.visma.net/access-management-api:companyGroups:write+https://access-management.api.visma.net/access-management-api:rolegroups:read+https://access-management.api.visma.net/access-management-api:rolegroups:write+https://access-management.api.visma.net/access-management-api:defaultrolegroup:read+https://access-management.api.visma.net/access-management-api:defaultrolegroup:write+https://access-management.api.visma.net/access-management-api:roles:read+https://access-management.api.visma.net/access-management-api:distributors:admin+https://access-management.api.visma.net/access-management-api:distributors:viewer',
      account_settings: 'https://accountsettings.connect.identity.stagaws.visma.com',
      graph_api: "https://1rxolsmlxl.execute-api.eu-west-1.amazonaws.com/int",
      user_details_uri: 'https://accountsettings.connect.identity.stagaws.visma.com/'
    },
    stag: {
      wellknown_config: 'https://connect.identity.stagaws.visma.com/.well-known/openid-configuration',
      client_id: 'admin-ui',
      redirect_uri: 'https://admin-user-access.stag.visma.net/callback',
      post_logout_redirect_uri: 'https://admin-user-access.stag.visma.net',
      scope: 'openid+email+profile+custom+roles+offline_access+graphapi:read+authenticationsettingsapi:subscription:read+authenticationsettingsapi:role:read+authenticationsettingsapi:job:read+authenticationsettingsapi:job:create+access-management-api:notifications+access-management-api:customers:write+access-management-api:customers:read+access-management-api:companies:write+access-management-api:companies:read+access-management-api:users:write+access-management-api:users:read+access-management-api:customer-roles:write+access-management-api:company-roles:write+access-management-api:usergroups:write+access-management-api:usergroups:read+access-management-api:distributors:write+publicapi:user:update+access-management-api:users:custom-claims+access-management-api:companyGroups:read+access-management-api:companyGroups:write+access-management-api:rolegroups:read+access-management-api:rolegroups:write+access-management-api:defaultrolegroup:read+access-management-api:defaultrolegroup:write+access-management-api:roles:read+access-management-api:distributors:admin+access-management-api:distributors:viewer',
      account_settings: 'https://accountsettings.connect.identity.stagaws.visma.com',
      graph_api: "https://graph-api.connect.identity.stagaws.visma.com",
      user_details_uri: 'https://accountsettings.connect.identity.stagaws.visma.com/'
    },
    prod: {
      wellknown_config: 'https://connect.visma.com/.well-known/openid-configuration',
      client_id: 'admin-ui',
      redirect_uri: 'https://admin-user-access.visma.net/callback',
      post_logout_redirect_uri: 'https://admin-user-access.visma.net',
      scope: 'openid+email+profile+custom+roles+offline_access+graphapi:read+authenticationsettingsapi:subscription:read+authenticationsettingsapi:role:read+authenticationsettingsapi:job:read+authenticationsettingsapi:job:create+access-management-api:notifications+access-management-api:customers:write+access-management-api:customers:read+access-management-api:companies:write+access-management-api:companies:read+access-management-api:users:write+access-management-api:users:read+access-management-api:customer-roles:write+access-management-api:company-roles:write+access-management-api:usergroups:write+access-management-api:usergroups:read+access-management-api:distributors:write+publicapi:user:update+access-management-api:users:custom-claims+access-management-api:companyGroups:read+access-management-api:companyGroups:write+access-management-api:rolegroups:read+access-management-api:rolegroups:write+access-management-api:defaultrolegroup:read+access-management-api:defaultrolegroup:write+access-management-api:roles:read+access-management-api:distributors:admin+access-management-api:distributors:viewer',
      account_settings: 'https://accountsettings.connect.visma.com',
      graph_api: "https://graph-api.connect.visma.com",
      user_details_uri: 'https://accountsettings.connect.visma.com/'
    }
  },
  api: {
    localhost: 'http://localhost:4200',
    int: 'https://gw.access-management.api.aws.int.test.visma.net',
    stag: 'https://gw.access-management.api.odp.aws.stag.visma.net',
    prod: 'https://gw.access-management.api.odp.aws.visma.net'
  },
  notificationsHub: {
    localhost: {
      url: 'wss://notifications.odp.aws.stag.visma.net'
    },
    stag: {
      url: 'wss://notifications.odp.aws.stag.visma.net'
    },
    prod: {
      url: 'wss://notifications.odp.aws.visma.net'
    }
  }
};
