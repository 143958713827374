import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomainService } from '../domain/domain.service';
import { WindowRef } from '../domain/window-ref';
import { WellKnownConfig } from './well-known-config';
import { Observable } from 'rxjs';
import { Tenant } from './tenant';
import { SESSION_STATE, STATE, TOKEN_RESPONSE, VERIFIER, WELL_KNOWN_CONFIG } from './connect-storage-keys';
import { HOSTNAME } from '../domain/hostname';
import { Profile } from './profile';
import { TenantResponse } from './tenant-response';
import { AuthorizationResponse } from './authorization-response';
import { LAST_USED_TENANT_ID, LAST_USED_TENANT_NAME, WELCOME_DIALOG_SEEN } from './custom';
import { TokenResponse } from './token-response';
import CryptoJS from 'crypto-js';
import { PROFILE_ROLE } from './profile-role';
import { PATH } from '../const/path';
import { ADMIN_UI_CURRENT_TENANT } from './storage-keys';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private httpClient: HttpClient, private domainService: DomainService, private windowRef: WindowRef) { }

  getWellKnownConfig(hostname: string): Observable<WellKnownConfig> {
    const config = this.domainService.getConnectIdentityConfig(hostname);
    return this.httpClient.get<WellKnownConfig>(config.wellknown_config);
  }

  getConnectLoginUrl(hostname: string, skipTenantHint?: boolean): string {
    const wkcstr = localStorage.getItem(WELL_KNOWN_CONFIG);
    if (wkcstr) {
      const wellKonwnConfig: WellKnownConfig = JSON.parse(wkcstr);
      const config = this.domainService.getConnectIdentityConfig(hostname);
      const verifier = this.getVerifier();
      localStorage.setItem(VERIFIER, verifier);
      const state = this.getState();
      sessionStorage.setItem(STATE, state);
      const sha256 = this.getSha256(verifier);
      let url = `${wellKonwnConfig.authorization_endpoint}?client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&response_type=code&scope=${config.scope}&response_mode=query&state=${state}&code_challenge=${sha256}&code_challenge_method=S256`;
      if (!skipTenantHint) {
        const ctstr = sessionStorage.getItem(ADMIN_UI_CURRENT_TENANT);
        if (ctstr) {
          const tenant: Tenant = JSON.parse(ctstr);
          url = `${url}&tenant_hint=${tenant.tenant_id}`;
        }
      }
      return url;
    } else {
      throw new Error('No wellknown config in local storage!');
    }
  }

  getConnectLogoutUrl(hostname: string): string | null {
    const config = this.domainService.getConnectIdentityConfig(hostname);
    const wkcstr = localStorage.getItem(WELL_KNOWN_CONFIG);
    const trStr = sessionStorage.getItem(TOKEN_RESPONSE);
    sessionStorage.clear();
    localStorage.removeItem(VERIFIER);

    if (wkcstr) {
      const wellKnownConfig = JSON.parse(wkcstr);

      let token: TokenResponse;
      if (trStr) {
        token = JSON.parse(trStr);
      } else {
        return null;
      }
      return `${wellKnownConfig.end_session_endpoint}?id_token_hint=${token.id_token}&post_logout_redirect_uri=${config.post_logout_redirect_uri}`
    }
    return null;
  }

  getTokenRequest(authResponse: AuthorizationResponse, hostname: string): Observable<TokenResponse> {
    const config = this.domainService.getConnectIdentityConfig(hostname);
    const state = this.getState();
    sessionStorage.setItem(STATE, state);

    if (state !== authResponse.state) {
      throw new Error('State changed!');
    }
    const verifier = localStorage.getItem(VERIFIER);
    let body = `grant_type=authorization_code&code=${authResponse.code}&client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&code_verifier=${verifier}`;
    if (this.isEnvironmentMockedWithAws(hostname)) {
      const ctstr = sessionStorage.getItem(ADMIN_UI_CURRENT_TENANT);
      if (ctstr) {
        const tenant: Tenant = JSON.parse(ctstr);
        body = `${body}&tenant_hint=${tenant.tenant_id}`;
      }
    }
    const wkcstr = localStorage.getItem(WELL_KNOWN_CONFIG);

    if (wkcstr) {
      const wellKnownConfig: WellKnownConfig = JSON.parse(wkcstr);
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      return this.httpClient.post<TokenResponse>(wellKnownConfig.token_endpoint, body, { headers: headers });
    } else {
      throw new Error('No wellknown config in local storage!');
    }
  }

  refreshToken(refreshToken: string): Observable<TokenResponse> {
    const wkcstr = localStorage.getItem(WELL_KNOWN_CONFIG);
    if (wkcstr) {
      const hostname = this.windowRef.getHostname();
      const config = this.domainService.getConnectIdentityConfig(hostname);
      const body = `client_id=${config.client_id}&grant_type=refresh_token&refresh_token=${refreshToken}`
      const wellKnownConfig: WellKnownConfig = JSON.parse(wkcstr);
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      return this.httpClient.post<TokenResponse>(wellKnownConfig.token_endpoint, body, { headers: headers });
    } else {
      throw new Error('No wellknown config in local storage!');
    }
  }

  getTenantId(hostname: string): string | undefined {
    const tokenStr = sessionStorage.getItem(TOKEN_RESPONSE);
    if (tokenStr) {
      const token = JSON.parse(tokenStr);
      if (this.isEnvironmentMockedWithAws(hostname) && this.hasTenantIdInMockedEnvironment(token.access_token)) {
        return token.access_token.substring(token.access_token.length - 36);
      } else {
        const words = CryptoJS.enc.Base64.parse(token.access_token.split('.')[1]);
        const str = CryptoJS.enc.Utf8.stringify(words);
        const obj = JSON.parse(str);
        return obj.tenant_id;
      }
    }
    return undefined;
  }

  private isEnvironmentMockedWithAws(hostname: string): boolean {
    return HOSTNAME.localhost === hostname || HOSTNAME.int === hostname;
  }

  private hasTenantIdInMockedEnvironment(token: string): boolean {
    return token.includes("tenant");
  }

  handleTokenResponse(state: string, token: TokenResponse, hostname: string): void {
    localStorage.removeItem(VERIFIER);
    sessionStorage.removeItem(TOKEN_RESPONSE);
    sessionStorage.removeItem(SESSION_STATE);
    sessionStorage.removeItem(STATE);
    sessionStorage.setItem(TOKEN_RESPONSE, JSON.stringify(token));
    const tenant_id = this.getTenantId(hostname);
    if (tenant_id) {
      sessionStorage.setItem(SESSION_STATE, state);
    }
  }

  isAuthenticated(): boolean {
    return sessionStorage.getItem(SESSION_STATE) !== null;
  }

  getUserProfile(): Observable<Profile> {
    const wkcstr = localStorage.getItem(WELL_KNOWN_CONFIG);
    if (wkcstr) {
      const wellKnownConfig: WellKnownConfig = JSON.parse(wkcstr);
      return this.httpClient.get<Profile>(wellKnownConfig.userinfo_endpoint);
    }
    throw new Error('No wellknown config in local storage!');
  }

  getUserDetailsUri(hostname: string): string {
    const config = this.domainService.getConnectIdentityConfig(hostname);
    return config.user_details_uri;
  }

  getTenantResponse(hostname: string, page: number, limit: number, query?: string): Observable<TenantResponse> {
    const config = this.domainService.getConnectIdentityConfig(hostname);
    let params = new HttpParams().set('per_page', limit);
    params = params.set('page', page);
    params = params.set('include_totals', true);
    if (query) {
      params = params.append('search_attribute', 'organization_name');
      params = params.append('search_value', query);
    }
    const url = `${config.graph_api}/v1.0/tenants`;
    return this.httpClient.get<TenantResponse>(url, { params: params });
  }

  getTenantResponseById(hostname: string, tenantId: string): Observable<TenantResponse> {
    const config = this.domainService.getConnectIdentityConfig(hostname);
    let params = new HttpParams().set('per_page', 1);
    params = params.set('page', 1);
    params = params.set('include_totals', true);
    params = params.set('search_attribute', 'tenant_id');
    params = params.set('search_value', tenantId);
    const url = `${config.graph_api}/v1.0/tenants`;
    return this.httpClient.get<TenantResponse>(url, { params: params });
  }

  getTenantFromClaims(custom: { [key: string]: string }): Tenant | null {
    const tenantId = custom[LAST_USED_TENANT_ID];
    const name = custom[LAST_USED_TENANT_NAME];
    if (tenantId !== undefined && tenantId !== 'none' && name !== undefined && name !== 'none') {
      return { tenant_id: tenantId, organization_name: name } as Tenant;
    }
    return null;
  }

  showWelcomeDialog(custom: { [key: string]: string }): boolean {
    const value = custom[WELCOME_DIALOG_SEEN];
    if (!value) {
      return true;
    }
    return false;
  }

  setCurrentTenantOnStorage(context: Tenant): void {
    sessionStorage.setItem(ADMIN_UI_CURRENT_TENANT, JSON.stringify(context));
  }

  removeCurrentTenantOnStorage(): void {
    sessionStorage.removeItem(ADMIN_UI_CURRENT_TENANT);
  }

  patchCustomWithLastUsedTenant(tenantId: string, organizationName: string): Observable<{ [key: string]: string }> {
    const url = '/v1/me/customClaims';
    return this.httpClient.patch<any>(url, [{ op: 'add', path: '/lastUsedTenantId', value: tenantId },
    { op: 'add', path: '/lastUsedTenantName', value: organizationName }]);
  }

  patchCustomWithTheme(theme: string): Observable<{ [key: string]: string }> {
    const url = '/v1/me/customClaims';
    return this.httpClient.patch<any>(url, [{ op: 'add', path: '/theme', value: theme }]);
  }

  patchCustomWithWelcomeDialogSeen(): Observable<{ [key: string]: string }> {
    const url = '/v1/me/customClaims';
    return this.httpClient.patch<any>(url, [{ op: 'add', path: '/welcomeDialogSeen', value: 'true' }]);
  }

  getTranslation(language: string): Observable<{ [key: string]: { [key: string]: string; } }> {
    const url = `/assets/i18n/${language}.json`;
    return this.httpClient.get<{ [key: string]: { [key: string]: string; } }>(url);
  }

  handleUnauthorized() {
    const skipTenantHint = true;
    const hasWellKnowConfig = localStorage.getItem(WELL_KNOWN_CONFIG) !== null;
    const hostname = this.windowRef.getHostname();
    sessionStorage.clear();
    if (hasWellKnowConfig) {
      const url = this.getConnectLoginUrl(hostname, skipTenantHint);
      this.windowRef.setLocationHref(url);
    } else {
      this.getWellKnownConfig(hostname).subscribe(response => {
        localStorage.setItem(WELL_KNOWN_CONFIG, JSON.stringify(response));
        const url = this.getConnectLoginUrl(hostname, skipTenantHint);
        this.windowRef.setLocationHref(url);
      });
    }
  }

  getAccessToken(): string | null {
    const str = sessionStorage.getItem(TOKEN_RESPONSE);
    if (str !== null) {
      const tr: TokenResponse = JSON.parse(str);
      return tr.access_token;
    }
    return null;
  }

  getRoutingPathFromRoles(roles: string[]): string {
    if (roles.includes(PROFILE_ROLE.subscriberAdministrator)) {
      return PATH.context_customer_user_access;
    } else if (roles.includes(PROFILE_ROLE.companyAdministrator)) {
      return PATH.context_company_user_access;
    } else if (roles.includes(PROFILE_ROLE.buAccessAdministrator)) {
      return PATH.context_distributor_user_access;
    } else if(roles.includes(PROFILE_ROLE.buSubscriberViewer)){
      return PATH.context_distributor_subscribers_user_access;
    }
    return PATH.forbidden;
  }

  private getBase64URLEncode(value: any): string {
    return CryptoJS.enc.Base64.stringify(value)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  private getVerifier(): string {
    const value = CryptoJS.lib.WordArray.random(32);
    return this.getBase64URLEncode(value);
  }

  private getState(): string {
    let stored = sessionStorage.getItem(STATE);
    if (stored === null) {
      stored = this.getBase64URLEncode(CryptoJS.lib.WordArray.random(16));
    }
    return stored;
  }

  private getSha256(value: string): string {
    return this.getBase64URLEncode(CryptoJS.SHA256(value));
  }


}
